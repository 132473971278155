import { defineStore } from 'pinia';
import type { Group } from '~/types/shop';
export const useShopStore = defineStore({
  id: 'useShopStore',
  state: () => ({
    groups: <Group[]>[],
  }),
  actions: {
    setGroups(newGroups: Group[]) {
        this.groups = newGroups
    }
  }
});